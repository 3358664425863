/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// import {useDispatch} from 'react-redux'
// import * as Yup from 'yup'
// import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
// import {useFormik} from 'formik'
import {loginUserRequest} from '../../../../store/ducks/me/actions'
// import { useHistory } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
import {ApplicationState} from '../../../../store'
// import { User } from '../../../../store/ducks/users/types';
// import { authfromcookie } from '../../../../store/ducks/me/actions';
import {Alert, Modal} from 'react-bootstrap-v5'
import clsx from 'clsx'
// import Loading from '../../loading'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export function Login() {
  const [loading, setLoading] = useState(false)
  const [login, setLogin] = useState('')
  const [pass, setPass] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [messageReason, setMessageReason] = useState('')

  const {reason} = useParams()
  const dispatch = useDispatch()
  // const history = useHistory();

  useEffect(() => {
    console.log('Reason: ', reason)
    if (reason === 'another-connected-device') {
      setMessageReason(
        'Sua conta foi acessada por outro dispositivo. Faça o login novamente. Se o problema persistir, troque sua senha ou entre em contato com o suporte.'
      )
      setShowModal(true)
    }
  }, [])

  //Redux:
  const me = useSelector((state: ApplicationState) => state.me)

  // console.log('me login', me)
  // console.log("Cookie user", cookieUser)
  // if(me.error){
  //   setLoading(false);
  // }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
     console.log("LOGIN CLICKADO...")

    event.preventDefault()
    if (login && pass) {
      setLoading(true)
      me.error = false
      //console.log('System is trying to login user...')
      dispatch(loginUserRequest({email: login, password: pass}))
      //setLoading(false)
    } else {
      //errMsg('Dados inválidos, preencha novamente');
      //console.log('Erro!')
      //setLoading(false)
    }
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        //backdrop='static'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{(reason === 'another-connected-device') && 'CONTA DESCONECTADA'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="lead fs-4">
            {messageReason}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-lg btn-light-primary w-100 mb-2' onClick={() => setShowModal(false)}>Fechar</button>

        </Modal.Footer>


      </Modal>

      <form
        onSubmit={handleSubmit}
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      >
        {me.error ? <Alert variant='danger'>Erro: Login ou senha inválidos</Alert> : ''}
        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder'>Email</label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            style={{fontSize: 13}}
            id='login'
            name='email'
            placeholder='Digite seu e-mail'
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder mb-0'>Senha</label>
              {/* end::Label */}
              {/* begin::Link */}

              {/* end::Link */}
            </div>
          </div>

          <div className='input-group col-md-4'>
            {/* <input
            className='form-control '
            type='search'
            defaultValue='search'
            id='example-search-input'
          /> */}
            <input
              type={showPassword ? 'text' : 'password'}
              className='form-control form-control-lg form-control-solid py-2 border-right-0 border'
              style={{fontSize: 13}}
              id='senha'
              name='password'
              aria-describedby='emailHelp'
              placeholder='Digite sua senha'
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <span className='input-group-append'>
              {/* <div className='btn btn-outline-secondary border-left-0 border'>
              <i className={clsx('bi fs-3 bi-app-indicator bg-danger')}></i>
            </div> */}
              <div
                className='ml-2 absolute right-3 top-1/2 transform -translate-y-1/2 btn btn-outline-secondary border-left-0 border'
                onClick={() => setShowPassword(!!!showPassword)}
              >
                {showPassword ? (
                  <i className={clsx('bi fs-3 bi-eye-slash')}></i>
                ) : (
                  <i className={clsx('bi fs-3 bi-eye')}></i>
                )}
              </div>
            </span>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-light-primary w-100 mb-2'
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {(!loading || me.error) && <span className='indicator-label'>Entrar</span>}
            {loading && !me.error && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Conectando...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
        <Link
          to='/auth/forgot-password'
          className='link-dark fs-6 fw-bolder'
          // style={{marginLeft: '5px'}}
        >
          Esqueceu a senha?
        </Link>
      </form>
    </>
  )
}
