import {Reducer} from 'redux'
import {EventsState, EventsTypes} from './types'

const INITIAL_STATE: EventsState = {
  data: 0,
}

const reducer: Reducer<EventsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //Load
 
    case EventsTypes.ONLINE_USERS:
      return {...state, data: action.payload}

    default:
      return state
  }
}

export default reducer
