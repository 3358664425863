/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {Button, FormControl, InputGroup} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {searchRequest} from '../../../../../store/ducks/component/actions'
import {useSelector} from 'react-redux'
import {ApplicationState} from '../../../../../store'

export function HeaderWrapper() {
  const {pathname} = useLocation()
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  // const [showSearch, setShowSearch] = useState(false)
  const [searchText, setSearch] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const me = useSelector((state: ApplicationState) => state.me)

  const searchInput = (text: string) => {
    //console.log("Text to search", text)
    var new_str = text.replace(/\//g, '!!')
    //console.log("new_str", new_str)
    setSearch(new_str)
  }

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    console.log('Searching...', searchText)
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }

    if (searchText.length > 0) {
      //console.log("Searching...", searchText)
      //dispatch(searchRequest(searchText, me.me.id!.toString(), me.me.numTurma!.toString()))
      navigate('/search/'+searchText)
    }
    setSearch('')
    return false
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-mini-el.png')}
                className='h-30px'
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/logo-mini-el.png')}
                className='h-30px'
              />
            </Link>
            {/* <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2x mt-1' />
            Pesquisar */}
          </div>
        )}

        {/* <Button className='m-2' variant='outline-secondary' id='button-addon2' onClick={() => setShowSearch(!showSearch)}>
        <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2x m-2' />
        </Button> */}

        <form
          onSubmit={handleSubmit}
          className='m-2'
          style={{width: '100%', alignContent: 'center'}}
        >
          <InputGroup className='' style={{width: '100%', border: 0}}>
            {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
            <FormControl
              placeholder='Pesquisar...'
              //aria-label='Digite um tema'
              aria-describedby='basic-addon2'
              // autoFocus={true}
              //onChange={(e: any) => console.log(e)}\
              value={searchText}
              onChange={(e: any) => searchInput(e.target.value)}
            />
            <Button variant='secondary' id='button-addon2' type='submit'>
              <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1x' />
            </Button>
          </InputGroup>
        </form>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>

        {/* end::Wrapper */}
      </div>
    </div>
  )
}
