/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import {Annotation} from '../../../../store/ducks/annotation/types'
import Loading from '../../../design/loading'
import {
  createAnnotationRequest,
  loadAnnotationSingleRequest,
} from '../../../../store/ducks/annotation/actions'
import ReactTextareaAutosize from 'react-textarea-autosize'
import {Button, Toast} from 'react-bootstrap-v5'
import {Navigate, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../design/helpers'

type Props = {
  className: string
  selectedClass: any
  url?: string
}

const AnnotationWidget: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  selectedClass,
  url,
}) => {
  const annotationRedux = useSelector((state: ApplicationState) => state.annotation)
  // console.log('AnnotationRedux', annotationRedux)
  // const [validated, setValidated] = useState(false);
  const [annotation, setAnnotation] = useState('')
  const me = useSelector((state: ApplicationState) => state.me)
  
  const [oldMessage, setOldMessage] = useState<string>('')
  const [show, setShow] = useState(false)
  const [first, setFirst] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    setFirst(true)
  }, [selectedClass.id])

  useEffect(() => {

    console.log('annotationRedux.data', annotationRedux.data.message)
    console.log('oldMessage', oldMessage)

    if (oldMessage !== annotationRedux.data.message) {
      //console.log("É diferente.. ativa o toast")
      setShow(true)
      setOldMessage(annotationRedux.data?.message!)
    }
    // else {
    //   setOldMessage(annotationRedux.data?.message!)
    // }
  }, [annotationRedux.data.message, selectedClass.id])

  // useEffect(() => {
  //   setOldMessage(annotationRedux.data?.message!)
  //   setShow(false)
  // }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAnnotationSingleRequest(me.me.id!, Number(selectedClass.id)))
    // setValidated(false) // remove o validador das anotações
  }, [me, selectedClass.id, dispatch])

  // console.log('selectedClass.id', selectedClass.id)
  // console.log('me.me.id!', me.me.id!)
  // console.log('annotationRedux.data', annotationRedux.data ? 'nao' : 'sim')

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    // setValidated(true);
    // console.log("CLIKED")
    if (annotation) {
      //&& terms === true
      const annotationToSave: Annotation = {
        id: annotationRedux.data?.id,
        message: annotation,
        userId: me.me.id,
        componentId: Number(selectedClass.id),
      }
      // console.log('annotationToSave', annotationToSave)

      const isNew: boolean = annotationRedux.data ? false : true
      dispatch(createAnnotationRequest(annotationToSave, isNew))
      setFirst(false)
    }
    return false //prevent scroll top when click
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column pb-0 pb-lg-0'>
        <div className='text-dark fw-bolder fs-4 mb-5'>Minhas anotações privadas</div>
        <div className='flex-grow-1'>
          <div className='mb-0'>
            <div className='tab-pane active' id='timeline'>
              {/* Post */}
              <div className='post clearfix'>
                {annotationRedux.loading ? (
                  <Loading />
                ) : (
                  // <form noValidate validated={validated} onSubmit={handleSubmit} >
                  <form noValidate onSubmit={handleSubmit}>
                    <ReactTextareaAutosize
                      minRows={2}
                      className='form-control'
                      //rows={10}
                      style={{width: '100%'}}
                      placeholder=''
                      required
                      defaultValue={annotationRedux.data?.message || ''}
                      onChange={(e) => setAnnotation(e.target.value)}
                      name='description'
                    />
                    {/* <Form.Control.Feedback type="invalid">
                            Por favor digite uma anotação para salvar
                        </Form.Control.Feedback> */}

                    <button
                      type='submit'
                      className='float-end btn-block btn btn-light-primary mt-2'
                    >
                      Salvar
                    </button>
                    {/* --{oldMessage} 
                    <br/>--
                    {annotationRedux.data.message} */}
                    {show && !first && (
                      <div className='pt-2'>
                        <KTSVG
                          //className='svg-icon-5 svg-icon-alert'
                          className='svg-icon-1'
                          path='/media/icons/duotune/general/gen037.svg'
                          stroke='#12AC6B'
                        />
                        <small className='text-success'>Salvo</small>
                      </div>
                    )}
                    {/* <Toast
                      onClose={() => setShow(false)}
                      show={show}
                      delay={3000}
                      autohide
                      className='position-absolute'
                    >
                      <Toast.Header>
                        <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
                        <strong className='me-auto'>Escultura Labial</strong>
                        {/* <small>Agora</small> *x/}
                      </Toast.Header>
                      <Toast.Body>Anotação salva com sucesso!
                        
                        <Button className='btn-block btn btn-light-primary btn-sm mt-4 col-12' onClick={() => navigate('/annotation')}>Ver todas anotações</Button>
                      </Toast.Body>
                    </Toast> */}
                  </form>
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AnnotationWidget}
