/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import ChangePass from './components/changePass'
import {Login} from './components/Login'
// import Login from '../../../app/pages/login'
import {toAbsoluteUrl} from '../../design/helpers'

export function AuthPage() {
  useEffect(() => {
    // document.body.classList.add('bg-white')
    return () => {
      // document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{
          //backgroundColor: 'blue',
          height: '100vh',
          //width: '100vh',
          overflow: 'hidden',
          backgroundImage: `url(${toAbsoluteUrl('/media/bg-login.jpg')})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          opacity: 1,
        }}
      >
        <div className='row  rounded m-10 w-md-600px'>
          {/* bg-black opacity-75 */}
          <div className='col-md-6 col-12 d-flex flex-column justify-content-around align-items-center border-right min-h-150px'>
            <img alt='Logo' className='h-65px' src={toAbsoluteUrl('/media/logos/logo.png')} />
          </div>
          <div className='col-md-6 col-12 d-flex justify-content-center align-items-center'>
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
              {/* begin::Wrapper */}
              <div className='w-100 '>
                {/* <div className='w-100 w-sm-400px w-lg-500px bg-black opacity-75 rounded shadow-sm p-10 p-lg-15 mx-auto '> */}
                <Routes>
                  {/* <Route path='login' element={<Login />} /> */}
                  <Route path='login'>
                    <Route index element={<Login />} />
                    <Route path=':reason' element={<Login />} />
                  </Route>
                  <Route path='registration' element={<Registration />} />
                  <Route path='change/:email/:authKey' element={<ChangePass />} />
                  <Route path='forgot-password' element={<ForgotPassword />} />
                  {/* <Route path='auth' element={<Navigate to='/auth/login'/>}></Route> */}
                  {/* <Route element={<Navigate to='/auth/login'/>}></Route> */}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
