/**
 * Action types
 */
export enum EventsTypes {
  //Load
  ONLINE_USERS = '@events/ONLINE_USERS',
}

/**
 * State type
 */
export interface EventsState {
  readonly data: number
}
