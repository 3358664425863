import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {Trainning} from '../pages/Trainning'
import {Modules} from '../pages/onlinecourse/modules/Modules'
import {Class} from '../pages/onlinecourse/class/Class'
import {Annotation} from '../pages/onlinecourse/annotation/Annotation'
import {Agenda} from '../pages/onlinecourse/agenda/Agenda'
import Suporte from '../pages/onlinecourse/support'
import Search from '../pages/search'
//import { AuthPage } from '../modules/auth'
import ChangePassInternal from '../pages/changepassinternal'
import {EditProfile} from '../pages/profile/edit/EditProfile'

import {MyProfile} from '../pages/profile/view/MyProfile'
import {useSelector} from 'react-redux'
import {ApplicationState} from '../../store'
import {Replay} from '../pages/onlinecourse/replay/Replay'
// import Renovation from '../pages/renovation/Renovation'
import {Certificate} from '../pages/onlinecourse/certificate/Certificate'
import * as io from 'socket.io-client'
import {useDispatch} from 'react-redux'
import {setOnlineUsersRequest} from '../../store/ducks/events/actions'

const MOMENT = require('moment')
// import ChangePass from '../../app/modules/'

export function PrivateRoutes() {
  //console.log("Bateu aqui..")
  const me = useSelector((state: ApplicationState) => state.me)
  const dispatch = useDispatch()

  //const [onlineUsers, setOnlineUsers] = React.useState(0)

  const setOnlineUsers = (data: any) => {
    dispatch(setOnlineUsersRequest(data))
  }

  useEffect(() => {
    const socket = io.connect('https://api.esculturalabial.com.br?id=' + me.me.id?.toString())
    //const socket = io.connect('http://localhost:3000?id=' + me.me.id?.toString())
    console.log('Trying to connect socket...')
    console.log('socket', socket)

    socket.on('connect', function () {
      console.log('Connected')
      // socket.emit('online', me.me.id, (response: any) => setOnlineUsers(response))
    })

    socket.on('qtdOnlineUsers', function (data) {
      console.log('Setando users online: ', data)
      setOnlineUsers(data)
    })

    socket.on('sessionid/' + me.me.id, function (data) {
      console.log('Mensagem exclusiva:' + me.me.id, data)
      if (me.me.sessionid !== data) {
        // alert(
        //   'Sua conta foi acessada por outro dispositivo. Faça o login novamente. Se o problema persistir, troque sua senha ou entre em contato com o suporte.'
        // );
        document.location.href = '/logout/another-connected-device'
      }
    })

    // socket.on('exception', function (data) {
    //   console.log('event', data)
    // })
    // socket.on('disconnect', function () {
    //   console.log('Disconnected')
    //   //socket.emit('offline', {id: me.me.id?.toString()})
    // })
    socket.on('error', function (data) {
      console.log('ERROR*********', data)
    })
  }, [me.me.id])

  //TRAVA PESSOA QUE NAO TEM PERFIL COMPLETO:
  if (
    !me.me.name ||
    !me.me.whatsapp ||
    !me.me.cpf ||
    !me.me.address ||
    !me.me.addressNumber ||
    !me.me.addressDistrict ||
    // !me.me.addressCountry ||
    !me.me.cityId ||
    !me.me.stateId ||
    !me.me.postalCode
  ) {
    return (
      <Routes>
        <Route path='*' element={<EditProfile />} />
      </Routes>
    )
  }

  var createdAt = MOMENT(Number(me.me.createdAt) * 1000) //.format('DD/MM/YYYY HH:mm')
  var now = MOMENT(Date()) //.format('DD/MM/YYYY HH:mm')
  let progress = parseInt((now.diff(createdAt, 'years', true) * 100).toFixed(2))

  // if (progress > 100) progress = 100

  // if (progress === 100) {
  //   return (
  //     <Routes>
  //       <Route path='*' element={<Renovation />} />
  //     </Routes>
  //   )
  // }
  //navigate('/edit')

  return (
    <Routes>
      <Route path='onlinecourses' element={<Trainning />} />

      <Route path='modules'>
        <Route path=':id' element={<Modules />} />
      </Route>
      <Route path='replay' element={<Replay />} />
      {/* <Route path="/class/:id/:module_id/:class_id" element={<Class/>} /> */}
      <Route path='class'>
        {/* <Route index element={<Class/>} /> */}
        <Route path=':id' element={<Class />}>
          <Route path=':module_id' element={<Class />}>
            <Route path=':class_id' element={<Class />}></Route>
          </Route>
        </Route>
      </Route>
      <Route path='annotation' element={<Annotation />} />
      <Route path='changePass' element={<ChangePassInternal />} />

      {/* <Route path="/agenda/:id?" element={<Agenda/>} /> */}
      <Route path='agenda'>
        <Route path=':id' element={<Agenda />} />
      </Route>
      <Route path='mysupport' element={<Suporte />} />
      <Route path='search/:text' element={<Search />} />

      <Route path='/edit' element={<EditProfile />} />
      {/* <Route path="profile">
          <Route path=":id" element={<ViewProfile/>}/>
        </Route> */}
      <Route path='myprofile' element={<MyProfile />} />
      <Route path='certificate' element={<Certificate />} />

      {/* <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/onlinecourses' />
        <Redirect to='error/404' /> */}
      <Route path='/auth/login' element={<Navigate to='/onlinecourses' />} />
      <Route path='index.html' element={<Navigate to='/onlinecourses' />} />
      <Route path='/index.html' element={<Navigate to='/onlinecourses' />} />
      <Route index element={<Navigate to='/onlinecourses' />} />
      {/* <Route path="*" element={<Navigate to="error"/>} /> */}
    </Routes>
  )
}
