import React, {useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {useDispatch} from 'react-redux'
import {Navigate, Route, Routes, useParams} from 'react-router-dom'
import {logoutUser} from '../../../store/ducks/me/actions'
import {User} from '../../../store/ducks/users/types'

// import * as auth from './redux/AuthRedux'

export function Logout() {
  const dispatch = useDispatch()
  const params = useParams()
  const {reason} = params

  // useEffect(() => {
  //   console.log('Reason: ', reason)
  //   if(reason)
  //   alert('Reason: ' + reason)	
  // }, [])

  const [cookies, setCookie, removeCookie] = useCookies(['userApp'])
  const cookieUser: User = cookies.userApp
  //console.log('########### cookies', cookies)
  removeCookie('userApp', {path: '/'})

  useEffect(() => {
    dispatch(logoutUser())
    document.location.reload()
  }, [cookieUser])
  return (
    <Routes>
      {reason && <Route index element={<Navigate to={'/auth/login/'+reason} />} />}
      {!reason &&<Route index element={<Navigate to='/auth/login' />} />}
    </Routes>
  )
}
