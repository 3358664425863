/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {
  KTSVG,
  toAbsoluteUrl,
  // toAbsoluteUrl
} from '../../design/helpers'
// import { ComponentState } from '../../../../store/ducks/component/types'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {AnnotationsState} from '../../../store/ducks/annotations/types'
import {Component, ComponentState} from '../../../store/ducks/component/types'
import {useSelector} from 'react-redux'
import {ApplicationState} from '../../../store'
import {Alert} from 'react-bootstrap-v5'
import ModalNotAvailable from '../ModalNotAvailable'
import ModalNoACcess from '../ModalNoAccess'
const MOMENT = require('moment')
require('moment-duration-format')
// const MOMENT= require( 'moment' );

type Props = {
  className: string
  component: ComponentState
  searchText: string
}

// let today = MOMENT().format( 'YYYY-MM-DD HH:mm:ss.000' );
// const add = (accumulator: number, a: number) => {
//   return accumulator + a;
// }

const SearchWidget: React.FC<React.PropsWithChildren<Props>> = ({className, component, searchText}) => {
  
  //console.log('SEARCHxxxxxxxxxx', component)
  const intl = useIntl()
  const me = useSelector((state: ApplicationState) => state.me)
  var createdAt = MOMENT(new Date(Number(me.me.createdAt) * 1000)) //.format('DD/MM/YYYY HH:mm')
  var now = MOMENT(new Date()) //.format('DD/MM/YYYY HH:mm')
  let blockAreas = now.diff(createdAt, 'years', true).toFixed(2) > 1
  let today = MOMENT().format('YYYY-MM-DD HH:mm:ss.000')

  const [showModalNotAvailable, setShowModalNotAvailable] = useState(false)
  const [showModalNoAccess, setShowModalNoAccess] = useState(false)
  const [data, setData] = useState<Component>({})

  return (
    <>
      <ModalNotAvailable
        show={showModalNotAvailable}
        setShow={setShowModalNotAvailable}
        data={data}
      />
      <ModalNoACcess show={showModalNoAccess} setShow={setShowModalNoAccess} data={data} />

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {/* {intl.formatMessage({id: 'MENU.SEARCH'})} */}
              Resultados para: {searchText}
            </span>
            {component.search.length > 0 && (
              <span className='text-muted mt-1 fw-bold fs-7'>
                {/* Clique no nome da aula para ser redirecionado. <br /> */}
                Quantidade: {component.search.length}
              </span>
            )}
            {component.search.length === 0 && (
              <span className='text-muted mt-1 fw-bold fs-7'>
                {/* Clique no nome da aula para ser redirecionado. <br /> */}
                Nenhum resultado foi encontrado para esse termo.
              </span>
            )}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className='tab-content'>
            {/* begin::Tap pane */}
            <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='border-0'>
                      <th className='p-0 w-150px'></th>
                      <th className='p-0 min-w-150px'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}

                  <tbody>
                    {component.search?.map((component: any, index) => {
                      // const terms = ["mentoria", "terça musical", "quinta teórica", "terca musical", "quinta teorica", "turma 2", "turma 1"]
                      // const result = terms.some(term => component.parentName.toLowerCase().includes(term))
                      let extra = component.extras?.filter(
                        (extra: any) => extra.keyExtra === 'url'
                      )[0]

                      let dataAvailable = MOMENT(
                        component.parent?.available[0].availableDate
                      ).format('YYYY-MM-DD HH:mm:ss.000')
                      let isAvailable = MOMENT(today).isAfter(dataAvailable) ? true : false
                      let hasAccess =
                        component.parent.parent.access.length > 0
                          ? component.parent.parent.access[0].status === '1'
                            ? true
                            : false
                          : false

                      console.log('EXTRA-VER', extra)
                      if (extra)
                        return (
                          <tr
                            key={index}
                            // className={result && blockAreas? 'block':''}
                          >
                            <td>
                              <Link
                                className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                                to={
                                  isAvailable && hasAccess
                                    ? '/class/' +
                                      component.parent.componentId +
                                      '/' +
                                      component.parent.id +
                                      '/' +
                                      component.id
                                    : '#'
                                }
                                onClick={() => {
                                  if (!isAvailable || !hasAccess) {
                                    setData(component)
                                    setShowModalNoAccess(true)
                                  }
                                }}
                                style={{display: 'flex'}}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                    pointerEvents: 'none',
                                    //backgroundColor: 'red',
                                    backgroundImage:
                                      isAvailable && hasAccess
                                        ? ''
                                        : `url(https://app.esculturalabial.com.br/files/cadeado.png)`,
                                    backgroundPosition: 'right top',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                  }}
                                >
                                  <div
                                  //className={!isAvailable || !hasAccess ? 'block' : ''}
                                  // className={
                                  //   !isAvailable || !hasAccess
                                  //     ? `disabled-blog`
                                  //     : ``
                                  // }
                                  >
                                    <iframe
                                      title='video'
                                      className={
                                        !isAvailable || !hasAccess
                                          ? 'embed-responsive-item rounded disabled-blog'
                                          : 'embed-responsive-item rounded'
                                      }
                                      src={extra.valueExtra}
                                      style={{
                                        width: '100%',
                                        pointerEvents: 'none',
                                        opacity: !isAvailable || !hasAccess ? '0.1' : '1',
                                        // backgroundImage: isAvailable && hasAccess
                                        //   ? ''
                                        //   : `url(${toAbsoluteUrl('/media/violaofeeling/cadeado.png')})`,
                                        // backgroundPosition: 'right top',
                                        // backgroundRepeat: 'no-repeat',
                                        // backgroundSize: 'cover',
                                        //backgroundColor: 'yellow',
                                      }}
                                      height={100}
                                      frameBorder={0}
                                      // allow='autoplay; fullscreen'
                                      // allowFullScreen
                                    />
                                  </div>
                                </div>
                              </Link>
                            </td>
                            <td>
                              <Link
                                className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                                to={
                                  isAvailable && hasAccess
                                    ? '/class/' +
                                      component.parent.componentId +
                                      '/' +
                                      component.parent.id +
                                      '/' +
                                      component.id
                                    : '#'
                                }
                                onClick={() => {
                                  if (!isAvailable || !hasAccess) {
                                    setData(component)
                                    setShowModalNoAccess(true)
                                  }
                                }}
                                style={{display: 'flex'}}
                              >
                                {component.name}
                              </Link>
                              {component.parent?.parent && (
                                <span className='text-muted fw-bold d-block'>
                                  {component.parent?.parent.name}
                                </span>
                              )}
                              {component.parent && (
                                <span className='text-muted fw-bold d-block'>
                                  {component.parent.name}
                                </span>
                              )}
                              <span className='text-muted fw-bold d-block'>
                                {component.parentName}
                              </span>
                              <span className='text-muted fw-bold d-block'>
                                Duração:{' '}
                                {MOMENT.duration(component.duration, 'seconds').format('hh:mm:ss', {
                                  trim: true,
                                })}
                              </span>

                              {/* <span className='text-muted fw-bold d-block'>
                                Tem access no curso?{' '}
                                {component.parent.parent.access.length ? 'sim' : 'nao'}
                              </span>
                              <span className='text-muted fw-bold d-block'>
                                Está disponível? {isAvailable ? 'sim' : 'nao'}
                              </span> */}
                            </td>

                            {/* <td className='text-end text-muted fw-bold'>{component.parentName}</td> */}
                            {/* component.search.map((component,index) => 
                            <div key={index}>
                              {component.parentName} {component.name} {component.id} {component.parentId} {component.firstId} {component.valueExtra}
                            </div>
                        ) */}
                          </tr>
                        )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {SearchWidget}
