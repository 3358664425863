import {call, put} from 'redux-saga/effects'
// import api from '../../../services/api'

import {
  //Load
  setOnlineUsersRequest
} from './actions'

// import {EventsTypes} from './types'

//Load
export function* setOnlineUsers(payload: ReturnType<typeof setOnlineUsersRequest>) {
  put(setOnlineUsersRequest(payload.payload))
}


